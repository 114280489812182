$(document).ready(initPage);

function initPage() {
  $('.header-toggler').click(function(){
    $('.header-nav').toggleClass('is-active');
    $('body').toggleClass('is-menu-open');
  });

  const swiperPartners = new Swiper(".swiper--home-partners", {
    spaceBetween: 80,
    loop: false,
    autoplay: {
      delay: 3000,
    },
    slidesPerView: 4,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 30
      },      
      768: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 80
      },

    }
  });

  const swiperPilotsLeft = new Swiper(".swiper--pilots-l", {
    direction: "vertical",
    spaceBetween: 20,
    pagination: {
      el: ".home-pilots-gallery--l .swiper-pagination",
      clickable: true,
    },
  });
  const swiperPilotsRight = new Swiper(".swiper--pilots-r", {
    direction: "vertical",
    spaceBetween: 20,
    pagination: {
      el: ".home-pilots-gallery--r .swiper-pagination",
      clickable: true,
    },
  });

  $('.flip-card').hover(function(){
    const _this = $(this);
    _this.addClass('is-hover');
    _this.addClass('is-rotated');
    
  }, function(){
    const _this = $(this);
    _this.removeClass('is-hover');
    setTimeout(() => {
      _this.removeClass('is-rotated')
    }, 500);
  });

  $('.flip-card').click(function(){
    const _this = $(this);
    if(!_this.hasClass('is-m-flip')) {
      _this.addClass('is-m-flip');
      _this.addClass('is-rotated');
    } else {
      _this.removeClass('is-m-flip');
      setTimeout(() => {
        _this.removeClass('is-rotated')
      }, 500);
    }
  })
}
